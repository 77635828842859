import * as CommonMessages from '../constants/common'

import {
  getAuthToken,
  getOrCreateFCAnonymousID,
  removeAuthToken,
  removeRefreshToken,
  setAuthToken,
  setRefreshToken,
  getBrowserName,
  getDeviceType,
  getOsName,
} from '../helpers'
import { logoutUser, setCurrentUser, setIsLoggedOut } from './auth'
import { setForcedLogOutMessage, showFailureMessage, showSuccessMessage } from './toast'

import ActionTypes from '../constants/actionTypes'
import { ApiService } from '../services'
import { batch } from 'react-redux'
import { config } from '../config'
import { emitter } from '../services/eventemitter'
import { fc_logger } from '../helpers/fc_logger'
import { handleApiErrors } from '../helpers/handleApiErrors'
import jwt_decode from 'jwt-decode'
import { resetAuth } from './auth'
import { sanitizeCurrentUrlPreRedirect } from '../helpers/login_controller'
import { IConfig } from '../interfaces'
import Action from '../constants/actionTypes'
import {FC_PREBID_API_RESPONSE} from '../constants/prebid';

export const updateBidResponse = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_BID_RESPONSE,
    payload,
  }
}

export const updatePaywall = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_PAYWALL,
    payload,
  }
}

export const updateLastTransaction = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_LAST_TRANSACTION,
    payload,
  }
}

export const updateRecommendedArticles = (articles: any[]) => ({
  type: ActionTypes.UPDATE_RECOMMENDED_ARTICLES,
  articles,
})

export const updateAskPrice = (askPrice?: Number) => ({
  type: ActionTypes.UPDATE_ASK_PRICE,
  payload: askPrice,
})

export const updateConfig = (config: any) => ({
  type: ActionTypes.UPDATE_CONFIG,
  config,
})

export const updateButtonState = (enableButton: boolean) => ({
  type: ActionTypes.ENABLE_BUTTON,
  enableButton,
})

const updateWallet = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_DEFAULT_WALLET,
    payload,
  }
}

const setPartnerTermsCheck = (payload: any) => {
  return {
    type: ActionTypes.SET_PARTNER_TERMS_CHECK,
    payload,
  }
}

export const addContentBlock = async (isElementToBeProtected: boolean, config: IConfig) => {
  const unlockElement = config?.paywallTargetElement || (await config.functionSelector())

  if (isElementToBeProtected) {
    addContentRestriction(config, unlockElement)
  } else {
    removeContentRestriction(config, unlockElement)
  }
}

export const addContentRestriction = (config: IConfig, unlockElement: any) => {
  if (!unlockElement) return

  if (config.lastVisibleElement) {
    const lastVisibleElement = unlockElement.querySelector(config.lastVisibleElement)
    for (let i = unlockElement.children.length - 1; i >= 0; i--) {
      {
        const node = unlockElement.children[i]
        if (node !== lastVisibleElement) {
          node.classList.add('fewcents-hidden')
        } else {
          break
        }
      }
    }
  } else {
    unlockElement.classList.add('fc_content-preview')
  }
}

export const removeContentRestriction = (config: IConfig, unlockElement: any) => {
  if (!unlockElement) return
  unlockElement.classList.remove('fc_prebid_content-preview')
  if (config.lastVisibleElement) {
    for (let i = unlockElement.children.length - 1; i >= 0; i--) {
      {
        const node = unlockElement.children[i]
        node.classList.remove('fewcents-hidden')
      }
    }
  } else {
    unlockElement.classList.remove('fc_content-preview')
  }
}

const getPrebidResponse= ()=>{
  const {prebid_api_response} = (window as any).dataLayer.find((d:any)=> d[FC_PREBID_API_RESPONSE])
  return prebid_api_response
}
const cache = new Map()

const createCacheWrapper = async (data: any, getState: Function) => {
  fc_logger.info('inside createCacheWrapper')
  const os = getOsName()
  const browser = getBrowserName()
  const auth = getState().auth
  const track = getState().paywall.config.track;

  const { isPrebid } = getState().paywall.config;

  fc_logger.info('paywall data');
  fc_logger.info(getState().paywall);

  fc_logger.info(`is Prebid: ${isPrebid}`);

  const deviceType = getDeviceType()
  data.customerId = auth?.user?.id

  const dataPayload = {
    ...data,
    isSIMEnabled: !!data.simUserEmail,
    fcAnonymousId: getOrCreateFCAnonymousID(),
    deviceOS: os,
    deviceBrowser: browser,
    deviceType,
  }

  fc_logger.info('dataPayload created')
  fc_logger.info(dataPayload)

  const { accessToken } = await getAuthToken()
  if (accessToken) {
    fc_logger.info('found auth token in createCacheWrapper')

    if (!data.customerId || data.customerId === undefined) {
      fc_logger.info("didn't find user in state, getting details")
      const decoded: any = jwt_decode(String(accessToken))
      const email = decoded.username
      const customerData = await ApiService.get(`/v1/customer/${email}`)
      dataPayload.customerId = customerData.data.data.id
    }

    fc_logger.info('creating loggedInBid')
    track(['create_bid_called', { type: 'loggedIn' }], {})
    
    let bidDetails;
    if (!isPrebid) {
      fc_logger.info(`Calling createLoggedInBid as isPrebid is: ${isPrebid}`);
      // if (typeof FewcentsPixel === 'function') {
      //   FewcentsPixel('offerwall', 'create-bid', 'api-call', '/v2/fbid/createLoggedInBid');
      // }
      bidDetails = await ApiService.post(`/v2/fbid/createLoggedInBid`, dataPayload);
    } else {
      const fewCentsBidResponse = getState().paywall.fewCentsBidResponse;
      const askPrice = getState().paywall.askPrice;
      const preBidApiPayload = {
        fewCentsBidId: getPrebidResponse().fewCentsBidId ,
        publisherId: getPrebidResponse().publisherId,
        publisherAsk: askPrice,
        isSIMEnabled: !!getPrebidResponse().simUserEmail,
        ...dataPayload,
      }
      fc_logger.info(`Calling postLoggedInBid as isPrebid is: ${isPrebid}`);
      // if (typeof FewcentsPixel === 'function') {
      //   FewcentsPixel('offerwall', 'create-bid', 'api-call', '/v2/fbid/postLoggedInBid');
      // }
      bidDetails = await ApiService.post(`/v2/fbid/postLoggedInBid`, preBidApiPayload);
    }

    if (bidDetails.status !== 200 || (bidDetails.status == 200 && !bidDetails.data.success)) {
      track(['create_bid_failed', { type: 'loggedIn' }], {})
      throw new Error(bidDetails.data.message)
    }
    let renderTime
    if (window.performance) {
      renderTime = Date.now() - window.performance.timing.navigationStart
    }
    track(['create_bid_success', { renderTime }], bidDetails.data['data'])
    analyticsTrackPaywall(bidDetails.data['data'])
    return bidDetails
  } else {
    fc_logger.info('creating loggedOutBid')
    track(['create_bid_called', { type: 'loggedOut' }], {});
  
    let bidDetails;
    if (!isPrebid) {
      fc_logger.info(`Calling createLoggedOutBid as isPrebid is: ${isPrebid}`);
      // if (typeof FewcentsPixel === 'function') {
      //   FewcentsPixel('offerwall', 'create-bid', 'api-call', '/v2/fbid/createLoggedOutBid');
      // }
      bidDetails = await ApiService.post(`/v2/fbid/createLoggedOutBid`, dataPayload);
    } else {
      const fewCentsBidResponse = getState().paywall.fewCentsBidResponse;
      const askPrice = getState().paywall.askPrice;
      const preBidApiPayload = {
        fewCentsBidId: getPrebidResponse().fewCentsBidId,
        publisherId: getPrebidResponse().publisherId,
        publisherAsk: askPrice,
        isSIMEnabled: !!getPrebidResponse().simUserEmail,
        ...dataPayload,
      }
      fc_logger.info(`Calling postLoggedOutBid as isPrebid is: ${isPrebid}`);
      // if (typeof FewcentsPixel === 'function') {
      //   FewcentsPixel('offerwall', 'create-bid', 'api-call', '/v2/fbid/postLoggedOutBid');
      // }
      bidDetails = await ApiService.post(`/v2/fbid/postLoggedOutBid`, preBidApiPayload);
    }
    
    if (bidDetails.status !== 200 || (bidDetails.status == 200 && !bidDetails.data.success)) {
      track(['create_bid_failed', { type: 'loggedOut' }], {})
      throw new Error(bidDetails.data.message)
    }
    cache.set(data.articleUrl, {
      createdAt: Date.now(),
      data: bidDetails,
    })
    let renderTime
    if (window.performance) {
      renderTime = Date.now() - window.performance.timing.navigationStart
    }
    track(['create_bid_success', { renderTime }], bidDetails.data['data'])
    analyticsTrackPaywall(bidDetails.data['data'])
    return bidDetails
  }

  function analyticsTrackPaywall(fewCentsBidResponse: any) {
    let reason = {
      enabledInCountry: true,
      articleActive: true,
      unlocked: false,
    }
    console.log(fewCentsBidResponse)
    if (fewCentsBidResponse) {
      reason.enabledInCountry = fewCentsBidResponse.isPaywallEnabledInCountry
      reason.articleActive = fewCentsBidResponse.articleActive
      if (!fewCentsBidResponse.isPaywallEnabledInCountry || !fewCentsBidResponse.articleActive) {
        track(['hide_fcwall', { reason }], fewCentsBidResponse)
      } else if (!fewCentsBidResponse.unlocked) {
        track(['show_fcwall', { reason }], fewCentsBidResponse)
      } else {
        reason.unlocked = true
        track(['hide_fcwall', { reason }], fewCentsBidResponse)
      }
    }
  }
}

export const getBubbleData = (fewCentsBidResponse: any) => async (dispatch: any, getState: any) => {
  if (fewCentsBidResponse && fewCentsBidResponse.customerName !== 'N/A') {
    const { customerId, fewCentsBidId, publisherId } = fewCentsBidResponse
    const { accessToken } = await getAuthToken()

    try {
      const url = `${config.ApiBaseUrl}/v2/fbid/getBalanceAndArticleRecommendation`
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ customerId, fewCentsBidId, publisherId }),
      })
      const verifiedResponse = handleApiErrors(response)
      const json = await verifiedResponse.json()
      const { data, message, success } = json
      const errorMessage = message ?? CommonMessages.COMMON_FAILURE
      if (response.status === 401) {
        const message = 'This content is currently unavailable. Please try again in some time.'
        dispatch(burstPublisher(message, errorMessage))
      }

      if (!success) throw new Error(errorMessage)

      const { recommendations, ...dataPayload } = data

      dispatch(updateRecommendedArticles(recommendations))
      dispatch(updateLastTransaction(dataPayload))
    } catch (error: any) {
      showFailureMessage(error.message)
    }
  }
}

export const callPaywallAction =
  (payload: any, isMedia: boolean) => async (dispatch: any, getState: any) => {
    fc_logger.info('inside callPaywallAction')
    fc_logger.info(payload)
    const bidResponse = getState()?.paywall?.fewCentsBidResponse;
    const config = getState()?.paywall?.config;
    if (!isMedia && bidResponse && bidResponse?.reload !== true) return

    try {
      const response = await createCacheWrapper(payload, getState)
      const { data, success, message } = response.data || {}
      /**
       * If the config.onSDKReady is registered
      */
      if (config.onSDKReady) {
        console.info('SKD ready');
        config.onSDKReady();
      }

      if (response.status === 200 && success) {
        emitter.fire(
          'onCreateBid',
          {
            articleActive: data.articleActive,
            isPaywallEnabledInCountry: data.isPaywallEnabledInCountry,
            articleIdentifier: data.articleIdentifier,
            fcUserHasAccess: data.fewCentsTransactionMode === 'alreadyunlocked',
            paywallError: false,
            contentCategory: data.contentCategory,
            contentPublishedDate: data.contentPublishedDate,
            contentAuthor: data.contentAuthor,
            contentTags: data.contentTags,
            userBrowser: data.userBrowser,
            userOS: data.userOS,
            userDevice: data.userDevice,
            fcUserAnonymousId: data.fcUserAnonymousId,
            userGeo: data.bidCountry,
            rewards: data.rewards,
          },
          bidResponse
        )
        console.log('firing onCreateBid inside sdk captured')
        // set partner terms check
        dispatch(setPartnerTermsCheck(data?.paywallSettings?.partnerTermsChecked))

        showSuccessMessage('Updated successfully')
        dispatch(updateBidResponse(data))

        if (data.unlocked === true) {
          fireOnAcceptBid(data, getState())
        }
      } else {
        if (response.status === 401) {
          dispatch(resetAuth())
        }
        throw new Error(message || CommonMessages.COMMON_FAILURE)
      }
    } catch (error: any) {
      emitter.fire('onCreateBid', {
        articleIdentifier: payload.articleIdentifier,
        paywallError: true,
      })
      showFailureMessage(`Error: ${error.message}`)
      const message = 'This content is currently unavailable. Please try again in some time.'
      dispatch(burstPublisher(message, error.message))
      const config = getState()?.paywall?.config
      config.player?.hidePaywall()
    }
  }

function trackAnalyticsOnAccept(track: Function, bidResponse: any) {
  track(['plugin_widget_removed_on_accept'], bidResponse)
}

export const acceptFBidCall = () => async (dispatch: any, getState: any) => {
  const fewCentsBidResponse = getState().paywall.fewCentsBidResponse
  const askPrice = getState().paywall.askPrice
  const track = getState()?.paywall?.config?.track

  const payload = {
    fewCentsBidId: fewCentsBidResponse.fewCentsBidId,
    fewCentsTransactionMode: fewCentsBidResponse.fewCentsTransactionMode,
    askPrice,
  }

  try {
    const response = await ApiService.put(`/v2/fbid/acceptBid`, payload)
    const { data, success, message } = response.data || {}

    if (response.status === 200 && success) {
      showSuccessMessage('Updated successfully')

      dispatch(updateBidResponse(data))

      fireOnAcceptBid(data, getState())

      track(
        [
          'bid_accepted',
          {
            apiEndpoint: '/v2/fbid/acceptBid',
          },
        ],
        data
      )
      trackAnalyticsOnAccept(track, fewCentsBidResponse)
    } else {
      throw new Error(message || CommonMessages.COMMON_FAILURE)
    }
  } catch (error) {
    const err = error as any
    emitter.fire(
      'onAcceptBid',
      {
        unlocked: false,
        articleIdentifier: fewCentsBidResponse.articleIdentifier,
      },
      fewCentsBidResponse
    )

    showFailureMessage(err.message)
    const message = 'For security purposes, please login to Few¢ents again.'
    dispatch(burstPublisher(message, err.message))
  }
}

export const acceptFBidCallUsingReward = () => async (dispatch: any, getState: any) => {
  const fewCentsBidResponse = getState().paywall.fewCentsBidResponse
  const askPrice = getState().paywall.askPrice
  const track = getState()?.paywall?.config?.track

  const payload = {
    fewCentsBidId: fewCentsBidResponse.fewCentsBidId,
    fewCentsTransactionMode: fewCentsBidResponse.fewCentsTransactionMode,
    customerRewardWalletId: fewCentsBidResponse.customerRewardWalletId,
    askPrice,
  }

  try {
    const response = await ApiService.put(`/v2/fbid/acceptBidUsingReward`, payload)
    const { data, success, message } = response.data || {}

    if (response.status === 200 && success) {
      showSuccessMessage('Updated successfully')

      dispatch(updateBidResponse(data))

      fireOnAcceptBid(data, getState())

      track(
        [
          'bid_accepted_using_reward',
          {
            apiEndpoint: '/v2/fbid/acceptBidUsingReward',
          },
        ],
        data
      )
      trackAnalyticsOnAccept(track, fewCentsBidResponse)
    } else {
      throw new Error(message || CommonMessages.COMMON_FAILURE)
    }
  } catch (error) {
    const err = error as any
    emitter.fire(
      'onAcceptBid',
      {
        unlocked: false,
        articleIdentifier: fewCentsBidResponse.articleIdentifier,
      },
      fewCentsBidResponse
    )

    showFailureMessage(err.message)
    const message = 'For security purposes, please login to Few¢ents again.'
    dispatch(burstPublisher(message, err.message))
  }
}

export const getWallet = (user: any) => async (dispatch: any, getState: any) => {
  if (user) {
    try {
      const response = await ApiService.get(`/v1/customer/${user.id}/wallets`)
      const { data, success, message } = response.data || {}
      if (response.status === 200 && success) {
        showSuccessMessage('Updated successfully')
        dispatch(updateWallet(data))
      } else if (response.status === 401) {
        dispatch(resetAuth())
      } else {
        const errorMessage = message || CommonMessages.COMMON_FAILURE
        showFailureMessage(errorMessage)
      }
    } catch (error: any) {
      showFailureMessage(`Error: ${error.message}`)
    }
  }
}

export const getBidDetailsPostTopUp =
  (user: any, fewCentsBidResponse: any, config: any) => async (dispatch: any, getState: any) => {
    if (user) {
      let articleIdentifier = null
      try {
        const url = `/v2/fbid/getCustomerBid/${fewCentsBidResponse.fewCentsBidId}`
        const response = await ApiService.get(url)
        const { data, success, message } = response.data || {}
        if (response.status === 200 && success) {
          showSuccessMessage('Updated successfully')
          dispatch(updateBidResponse(data))

          if (data.unlocked === true) {
            fireOnAcceptBid(data, getState())
            trackAnalyticsOnAccept(config.track, fewCentsBidResponse)
          }
          dispatch(getBubbleData(fewCentsBidResponse))
        } else {
          const errorMessage = message || CommonMessages.COMMON_FAILURE
          if (response.status === 401) {
            const message = 'For security purposes, please login to Few¢ents again.'
            dispatch(burstPublisher(message, errorMessage))
          }
          articleIdentifier = data.articleIdentifier
          throw new Error(errorMessage)
        }
      } catch (error: any) {
        emitter.fire('onAcceptBid', {
          unlocked: false,
          articleIdentifier,
        })
        showFailureMessage(error.message)
      }
    }
  }

export enum WalletActions {
  GetTokenWithAcceptBid = 'redirectToCallGetTokenWithAcceptBid',
  TopUpThenCallGetTokenWithAcceptBid = 'redirectToTopUpThenCallGetTokenWithAcceptBid',
  CurrencyMismatch = 'redirectToCallCurrencyMisMatch',
  UnlockWithoutAcceptBid = 'getCustomerTokenAndUnlockWithoutAcceptBid',
}

const getEndpointForNextAction = (nextAction: String) => {
  if (
    nextAction === WalletActions.GetTokenWithAcceptBid ||
    nextAction === WalletActions.TopUpThenCallGetTokenWithAcceptBid
  )
    return 'getCustomerTokenAndAcceptBid'
  else if (nextAction === WalletActions.CurrencyMismatch)
    return 'processBidPostLoginForCurrencyMisMatch'
  else if (nextAction === WalletActions.UnlockWithoutAcceptBid)
    return 'getCustomerTokenAndUnlockWithoutAcceptBid'
  else return 'getCustomerTokenAndBid'
}

export const executeNextAction =
  (loginToken: any, deviceKey: any, nextAction: any) => async (dispatch: any, getState: any) => {
    const apiToBeCalled = getEndpointForNextAction(nextAction)
    fc_logger.info(`Calling api ${apiToBeCalled} `)
    fc_logger.info(getState())

    fc_logger.info(`found fewCentsBidResponse, calling api ${apiToBeCalled} `)
    let articleIdentifier = null
    const partnerTermsCheck = getState().auth.partnerTermsCheck
    try {
      const { askPrice, fewCentsBidResponse, config } = getState().paywall
      const askPriceToBeSent =
        apiToBeCalled === 'getCustomerTokenAndUnlockWithoutAcceptBid' ||
        apiToBeCalled === 'getCustomerTokenAndAcceptBid'
      const payload = {
        deviceKey,
        loginToken,
        fewCentsBidId: fewCentsBidResponse?.fewCentsBidId,
        ...(askPriceToBeSent && { askPrice }),
      }
      fc_logger.info(`Calling ${apiToBeCalled}`)
      const response = await ApiService.post(`/v2/fbid/${apiToBeCalled}`, payload)
      const { data, success, message } = response.data || {}
      if (response.status === 200 && success) {
        if (!data.customerInfo.customer.isGuestAccount) {
          setAuthToken(data.customerInfo.accessToken)
          setRefreshToken(data.customerInfo.refreshToken)
        }
        const { type, deviceKey, deviceId } = data.customerInfo
        const device = {
          deviceId,
          deviceKey,
          type,
        }

        batch(() => {
          if (!data.customerInfo.customer.isGuestAccount) {
            dispatch(setIsLoggedOut(false))
          }
          if (nextAction === 'redirectToCallCurrencyMisMatch') {
            fc_logger.info('unsetting askPrice')
            dispatch(updateAskPrice(undefined))
          }
          dispatch(updateBidResponse(data))
          dispatch(setCurrentUser(data.customerInfo.customer, device))
        })

        if (partnerTermsCheck) {
          emitter.fire('onFewcentsLogin', {
            customerEmail: data.customerInfo.customer.email,
            signupMethod: data.customerInfo.customer.signupMethod,
          })
        }

        if (/metered|fcnewusercredits|c2cgiftin/.test(data.fewCentsTransactionMode)) {
          config.track(['bid_accepted', { apiEndpoint: `/v2/fbid/${apiToBeCalled}` }], data)
        }

        if (data.unlocked === true) {
          fireOnAcceptBid(data, getState())
          trackAnalyticsOnAccept(config.track, fewCentsBidResponse)
          dispatch({ type: Action.SET_BUBBLE_OPEN, payload: false })
        }
      } else {
        articleIdentifier = data.articleIdentifier
        dispatch(resetAuth())
        throw new Error(message || CommonMessages.COMMON_FAILURE)
      }
    } catch (error: any) {
      emitter.fire('onAcceptBid', {
        unlocked: false,
        articleIdentifier: articleIdentifier,
      })
      const message = 'For security purposes, please login to Few¢ents again.'
      dispatch(burstPublisher(message, error.message))
    } finally {
      window.history.replaceState(
        { additionalInformation: 'reset original url' },
        document.title,
        sanitizeCurrentUrlPreRedirect()
      )
    }
  }

export const burstPublisher =
  (message: string, reason: string) => async (dispatch: any, getState: any) => {
    const track = getState()?.paywall?.config?.track
    track && track(['burst_publisher_executed'], { reason: reason })
    fc_logger.info('Paywall Burst Publisher Executed')
    try {
      setForcedLogOutMessage(message)
      removeAuthToken()
      removeRefreshToken()
      dispatch(logoutUser())
    } catch {
      removeAuthToken()
      removeRefreshToken()
      window.location.reload()
    }
  }

export const canShowOverlay =
  (element: any, isTipjar: boolean) => (dispatch: any, getState: any) => {
    const { config, loginMethodCount } = getState().paywall
    const isGuest = getState().user?.isGuestAccount
    const isAuthenticated = getState().auth?.isAuthenticated
    const isAuthenticatedNormalUser = isAuthenticated && !isGuest
    let paywallHeight = 0
    if (isTipjar) {
      paywallHeight = isAuthenticatedNormalUser ? 230 : 320
    } else {
      paywallHeight = isAuthenticatedNormalUser ? 250 : 280
      const settings = getState().fewCentsBidResponse?.paywallSettings
      if (!settings?.showRewardOption) {
        paywallHeight = paywallHeight + 54
      }
      if (loginMethodCount > 3) {
        paywallHeight = paywallHeight + 44
      }
    }
    const paywallWidth = isTipjar ? 560 : 360

    const { height, width } = element.getBoundingClientRect()
    const showOverlay = height < paywallHeight || width < paywallWidth
    dispatch(updateConfig({ showOverlay }))
    config.track(['updating_paywall_layout', { showOverlay }])
  }

  const fireOnAcceptBid = (data: any, state:any) => {
    emitter.fire(
      'onAcceptBid',
      {
        fewCentsBidId: data.fewCentsBidId,
         publisherId: data.publisherId,
         articleId: data.articleId,
         unlocked: data.unlocked, 
         articleIdentifier: data.articleIdentifier,  
         contentPrice: data.articlePrice,
         customerName: data.customerName,
         customerEmail: state?.auth?.user?.email,
         bidMode: data.fewCentsTransactionMode
      },
      data
    )
  }
